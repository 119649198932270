require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')
require('chart.js')
require('uikit')
require('uikit/dist/js/uikit-icons')
require('flatpickr')
require('@fortawesome/fontawesome-free/js/all')
require('parsleyjs')
require('select2')
require('@nathanvda/cocoon')
require('app/app')

require('app/segment')

import 'styles/app'