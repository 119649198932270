window.segment = (function(){
  var init = function(options){
    loadCustomers(options.segment);
    loadPath(options.segment);
  }

  var loadCustomers = function(segment){
    $('#customers-wrapper').html('Carregando clientes...');
    
    $.get('/app/rfm_segments/'+ segment.id +'/customers', function(response){
      $('#customers-wrapper').html(response);
    });
  }

  var loadPath = function(segment){
    $('#path-wrapper').html('Carregando segmentos...');
    
    $.get('/app/rfm_segments/'+ segment.id +'/path', function(response){
      $('#path-wrapper').html(response);
    });
  }

  return {
    init: init
  }
}());